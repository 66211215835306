import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { fromEventPattern } from 'rxjs';
import { NodeEventHandler } from 'rxjs/internal/observable/fromEvent';

import { ApplicationInsightsService } from '../../monitoring/application-insights/application-insights.service';
import { AUTHENTICATION_CONSTANTS } from '../../constants/authentication.constants';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../../services/utils/local-storage.service';
import { RoadnetService } from '../../services/roadnet/roadnet-service';
import { SsoAuthenticationService } from '../../services/authentication/sso-authentication.service';

const AUTHCOOKIE = 'auth';
const EVENTTYPE = 'load';
const LOCAL_STORAGE_KEY_ALLOWED_ROUTES = 'allowed_routes';
const LOGOUTIFRAME = 'logout';
const PATH = '/supplynet-logout';
const SESSIONCOOKIE = 'session';
const USERLANGUAGECOOKIE = 'language';
const USERSESSIONCOOKIE = 'userSession';

/**
 * @class
 * @description - SupplyNet Logout Component class.
 */
@Component({
  selector: 'app-logout',
  styleUrls: ['./supplynet-logout.component.scss', '../../app.component.scss'],
  templateUrl: './supplynet-logout.component.html'
})
export class SupplyNetLogoutComponent implements OnInit {
  public loggedOut: boolean;
  public url: string = environment.clientUrl + PATH;
  public urlSafe: SafeResourceUrl;

  /**
   * @description - Creates an instance of SupplyNet Logout Component class.
   * @param { ApplicationInsightsService } applicationInsightsService - Application Insights service.
   * @param { CookieService } cookieService - Authentication service.
   * @param { LocalStorageService } localStorageService - Local storage service.
   * @param { RoadnetService } roadnetService - Form Builder service.
   * @param { Router} router - Tenant name from user to try login.
   * @param { SsoAuthenticationService} ssoAuthService - Tenant name from user to try login.
   * @param { DomSanitizer } sanitizer - Local storage service.
   */
  constructor(
    private applicationInsightsService: ApplicationInsightsService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private roadnetService: RoadnetService,
    private router: Router,
    private ssoAuthService: SsoAuthenticationService,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * @description - Runs all necessary functions when the Logout component is first loaded.
   *
   * @returns { void }
   */
  public ngOnInit(): void {
    this.loggedOut = false;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);

    const logout = fromEventPattern(
      this.onCompleteLoad
    );

    logout.subscribe(async () => {
      await this.deleteCookies().then(() => {
        this.removeExternalInterfacesConfig();
        this.applicationInsightsService.clearUserId();
        this.router.navigateByUrl('/supplynet-login');
        this.loggedOut = true;
      });

      await this.ssoAuthService.userLogout();
    });
  }

  /**
   * @description - Creates EventListener for iFrame.
   * @param { NodeEventHandler } handler - NodeEvent generated from EventPattern.
   *
   * @returns { void }
   */
  public onCompleteLoad(handler: NodeEventHandler): void {
    const iframe = document.getElementById(LOGOUTIFRAME);
    iframe.addEventListener(EVENTTYPE, handler);
  }

  /**
   * @description - Deletes auth, session and userSession cookies.
   *
   * @returns { Promise<void> }
   */
  public async deleteCookies(): Promise<void> {
    this.cookieService.delete(USERSESSIONCOOKIE, '/');
    this.cookieService.delete(AUTHCOOKIE, '/');
    this.cookieService.delete(SESSIONCOOKIE, '/');
    this.localStorageService.removeItem(LOCAL_STORAGE_KEY_ALLOWED_ROUTES);
    this.localStorageService.removeItem(USERLANGUAGECOOKIE);
    this.localStorageService.removeItem(AUTHENTICATION_CONSTANTS.IS_TEP_AUTHENTICATED);
    this.localStorageService.removeItem(AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_USERNAME_KEY);
    this.localStorageService.removeItem(AUTHENTICATION_CONSTANTS.LOCAL_STORAGE_SHIPPER_REALM_KEY);
  }

  /**
   * @description - Removes all external interfaces stored config.
   *
   * @returns { void }
   */
  private removeExternalInterfacesConfig(): void {
    this.roadnetService.removeConfig();
  }
}
