export const MOBILITY_CONSTANTS = {
  ALL_DAYS_LENGTH: 7,
  DESTINATION_MODEL_DESC: 'Destino',
  LOWER_DAY_ABBREVIATION_LIMIT: 0,
  MANUAL_DELIVERY_ACCEPTED_FILE_TYPES: ['image/jpeg', 'image/jpg', 'image/png'],
  ORIGIN_MODEL_DESC: 'Origen',
  SELECT_ALL: 0,
  SERVICE_DAYS: [
    { value: 1, label: 'locationCreateTags.weekDays.monday', dayName: 'monday' },
    { value: 2, label: 'locationCreateTags.weekDays.tuesday', dayName: 'tuesday' },
    { value: 3, label: 'locationCreateTags.weekDays.wednesday', dayName: 'wednesday' },
    { value: 4, label: 'locationCreateTags.weekDays.thursday', dayName: 'thursday' },
    { value: 5, label: 'locationCreateTags.weekDays.friday', dayName: 'friday' },
    { value: 6, label: 'locationCreateTags.weekDays.saturday', dayName: 'saturday' },
    { value: 7, label: 'locationCreateTags.weekDays.sunday', dayName: 'sunday' }
  ],
  SERVICE_DAYS_CONTROL: 'serviceWindowDaysControl',
  UPPER_DAY_ABBREVIATION_LIMIT: 3,
};
